var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", { staticClass: "font-weight-regular" }, [_vm._v("Instructors")]),
      _c(
        "v-list",
        [
          _vm._l(_vm.instructors, function(instructor, index) {
            return [
              _c(
                "v-list-item",
                {
                  key: index,
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("select", instructor)
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-avatar", [
                        _c("img", {
                          attrs: { src: instructor.profile_photo_url }
                        })
                      ])
                    ],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(" " + _vm._s(instructor.name) + " | "),
                    _c("span", { staticClass: "font-weight-light" }, [
                      _vm._v(_vm._s(instructor.email))
                    ])
                  ]),
                  _c(
                    "v-list-item-action-text",
                    [
                      _c(
                        "v-btn",
                        { attrs: { small: "", color: "secondary" } },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-account")
                          ]),
                          _vm._v(" View ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              index < _vm.instructors.length - 1
                ? _c("v-divider", { key: "divider-" + index })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }