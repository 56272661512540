<template>
    <div>
        <h4 class="font-weight-regular">Instructors</h4>
        <v-list>
            <template v-for="(instructor, index) in instructors">
                <v-list-item
                    :key="index"
                    link
                    @click="$emit('select', instructor)"
                >
                    <v-list-item-icon>
                        <v-avatar>
                            <img :src="instructor.profile_photo_url" />
                        </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ instructor.name }} |
                        <span class="font-weight-light">{{
                            instructor.email
                        }}</span>
                    </v-list-item-title>
                    <v-list-item-action-text>
                        <v-btn small color="secondary">
                            <v-icon small>mdi-account</v-icon>
                            View
                        </v-btn></v-list-item-action-text
                    >
                </v-list-item>
                <v-divider
                    v-if="index < instructors.length - 1"
                    :key="`divider-${index}`"
                ></v-divider>
            </template>
        </v-list>
    </div>
</template>
<script>
export default {
    name: "instructor-list",
    components: {},
    props: {
        instructors: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>